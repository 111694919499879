<template>
    <div class="flex flex-col gap-y-2 bg-beige px-6 py-5">
        <h4
            class="font-semibold text-base mb-2"
            v-text="title"
        />
        <p
            class="text-sm mb-2"
            v-text="description"
        />

        <div
            v-for="cookie in cookies"
            :key="cookie.id"
            class="flex items-center my-2"
        >
            <form-toggle
                :model-value="cookieStatus[cookie.id]"
                @update:model-value="updateCookie(cookie.id, $event)"
            />

            <p class="text-sm ml-4 uppercase font-semibold">{{ cookie.title }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps<{
    cookies: Cookie[];
    title: string;
    description: string;
}>();

const cookieStore = useCookieStore();
const { cookieStatus } = storeToRefs(cookieStore);

const updateCookie = (id: string, checked: boolean) => {
    cookieStore.updateCookie(id, checked);
};
</script>
