<template>
    <div>
        <div
            v-for="cookie in allCookies"
            :key="cookie.id"
        >
            <table class="mt-4 text-sm cookie-list">
                <tbody>
                    <tr>
                        <td v-text="$t('cookie-banner.list.name')" />
                        <td v-text="cookie.title" />
                    </tr>
                    <tr>
                        <td v-text="$t('cookie-banner.list.provider')" />
                        <td v-text="cookie.provider" />
                    </tr>
                    <tr>
                        <td v-text="$t('cookie-banner.list.purpose')" />
                        <td v-text="cookie.purpose" />
                    </tr>
                    <tr>
                        <td v-text="$t('cookie-banner.list.data')" />
                        <td>
                            <a
                                :href="cookie.privacy_policy"
                                target="_blank"
                                v-text="cookie.privacy_policy"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td v-text="$t('cookie-banner.list.names')" />
                        <td v-text="cookie.names ? cookie.names.join(', ') : ''" />
                    </tr>
                    <tr>
                        <td v-text="$t('cookie-banner.list.expiry')" />
                        <td v-text="cookie.expires" />
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" setup>
interface CookieList {
    necessary: Cookie[];
    statistic: Cookie[];
    marketing: Cookie[];
}

const props = defineProps<{
    cookies: CookieList;
}>();

const allCookies = computed(() => {
    return props.cookies["necessary"].concat(props.cookies["statistic"]).concat(props.cookies["marketing"]);
});
</script>

<style scoped>
.cookie-list tr td {
    @apply py-0.5;
}
.cookie-list tr td:first-child {
    @apply pr-4 uppercase;
}
@screen sm {
    .cookie-list tr td:first-child {
        @apply pr-16;
    }
}
</style>
